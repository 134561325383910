<template>
  <v-container fluid>
    <AppTitleBar>
      <template v-slot:title>{{ $t('title') }}</template>
    </AppTitleBar>

    <VWidget>
      <template v-slot:title>{{ $t('organizations') }}</template>
      <template v-slot:content>
        <v-container fluid grid-list-md>
          <v-data-iterator v-if="hasOrganizations" :items="organizations">
            <template v-slot:default="{ items }">
              <v-row>
                <v-col v-for="item in items" :key="item.id" cols="12" sm="6" md="4" lg="3">
                  <v-card outlined class="hover-border">
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar>
                          <img :src="item.avatar" v-if="item.avatar" />
                          <v-icon v-else v-text="$vuetify.icons.values.organization"></v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.display }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="default" icon depressed small @click="viewItem(item)">
                        <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </template>
    </VWidget>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { AppTitleBar, VWidget, VLoading } from '@argon/app/components'
import { NAMESPACE as APP_NAMESPACE } from '@argon/app/store'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import organizationPerimeter from '@argon/iam/organizations/perimeters'

export default {
  components: {
    AppTitleBar,
    VWidget
  },
  perimeters: [organizationPerimeter],
  computed: {
    ...mapState(ORG_NAMESPACE, ['activeOrg']),
    ...mapState(ME_NAMESPACE, ['organizations', 'profile', 'account']),
    hasOrganizations() {
      return this.organizations.length > 0
    }
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: 'orgOverview',
        params: { orgRef: item.refname }
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "organizations": "@:labels.organizations",
    "title": "@:labels.overview"
  },
  "fr": {
    "organizations": "@:labels.organizations",
    "title": "@:labels.overview"
  }
}
</i18n>
